import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/aboutSideMenu.scss";

export default function AboutSideMenu() {
  const data = useStaticQuery(graphql`
    query aboutSideMenuLinks {
      site {
        siteMetadata {
          aboutMenu {
            link
            name
          }
        }
      }
    }
  `);

  return (
    <div id="aboutSideMenu">
      <Row className="mt-lg-5 mt-md-4 p-3 white-bg">
        <Col>
          <Nav
            defaultActiveKey={data.site.siteMetadata.aboutMenu.link}
            className="flex-column"
          >
            <h2 className="menu-title">About Meridian</h2>
            {data.site.siteMetadata.aboutMenu.map((link, index) => (
              <Nav.Item key={index++}>
                <Link to={link.link}>
                  <Nav.Link as="span" eventKey={link.link}>
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      color="#0078ae"
                    />
                    {link.name}
                  </Nav.Link>
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
    </div>
  );
}
