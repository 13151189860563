import * as React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import "./styles/locationsModule.scss";

function Locations() {
  return (
    <StaticQuery
      query={graphql`
        query LocationsModule {
          allMdx(
            filter: { frontmatter: { tags: { regex: "/(location)/" } } }
            sort: { fields: frontmatter___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                card {
                  address_line_one
                  address_line_two
                  link
                  phone
                  thumb
                }
                title
              }
            }
          }
        }
      `}
      render={(data) => (
        <Row id="explore_the_centers" className="white-bg">
          <Col className="p-4">
            <h1>Explore The Centers</h1>
            <Row className="overview">
              {data.allMdx.nodes.map((node) => (
                <Col xs={12} lg={6} className="p-2">
                  <div className="img__wrap mb-2">
                    <Link to={node.frontmatter.card.link}>
                      <img
                        className="img-fluid img__img"
                        src={node.frontmatter.card.thumb}
                        alt={`${node.frontmatter.title} thumbnail`}
                      />
                      <div className="img__description_layer">
                        <p className="img__description">EXPLORE THE CENTER »</p>
                      </div>
                    </Link>
                  </div>
                  <h3 className="mb-1">{node.frontmatter.title}</h3>
                  <Row>
                    <Col xs={3} md={2} lg={1} className="me-xxl-n3 text-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt} color="#0078ae" />
                    </Col>
                    <Col xs={9} md={10} lg={11} className="mb-1">
                      <p className="icon__text mb-0">
                        {node.frontmatter.card.address_line_one}
                        <br />
                        {node.frontmatter.card.address_line_two}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} md={2} lg={1} className="me-xxl-n3 text-center">
                      <FontAwesomeIcon icon={faPhoneAlt} color="#0078ae" />
                    </Col>
                    <Col xs={9} md={10} lg={11}>
                      <p className="icon__text">
                        <Link to={`tel:${node.frontmatter.card.phone}`}>
                          {node.frontmatter.card.phone}
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    />
  );
}

export default Locations;
